<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-26 11:35:40
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:32:41
 * @FilePath: /mediatom-web/src/components/AccountModal/PlatForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="plat-account-form-container">
    <a-form-model-item v-if="+platId === 1083" prop="secretKey" label="行业分类">
      <a-input v-model.trim="formquery.secretKey" placeholder="请输入一级类目"></a-input>
    </a-form-model-item>
    <a-form-model-item v-if="+platId === 1083" prop="keyId" :wrapperCol="{offset: labelSpan}">
      <a-input v-model.trim="formquery.keyId" placeholder="请输入二级类目"></a-input>
    </a-form-model-item>
    <a-divider v-if="+platId === 1046">高级配置</a-divider>
    <a-form-model-item v-if="+platId === 1046" prop="dspTaskSwitch" label="dspTask任务">
      <div class="wrapper">
        <a-radio-group name="radioGroup" v-model="formquery.dspTaskSwitch">
          <a-radio :value="1"> 开启 </a-radio>
          <a-radio :value="0"> 关闭 </a-radio>
        </a-radio-group>
        <a-button type="link" @click="handleAddTask" v-if="formquery.dspTaskSwitch === 1">新增任务</a-button>
        <a-button type="link" v-else></a-button>
      </div>
    </a-form-model-item>
    <a-form-model-item v-if="+platId === 1046 && formquery.dspTaskSwitch === 1" prop="callbackUrl" label="回调地址">
      <a-input :placeholder="`请输入回调地址`" v-model.trim="formquery.callbackUrl"></a-input>
    </a-form-model-item>
    <template v-if="+platId === 1046 && +formquery.dspTaskSwitch === 1">
      <template v-for="(item, index) in formquery.dspTasks">
        <a-form-model-item :key="'task' + index" :label="`任务${index + 1}`">
          <div class="clear-task">
            <a-icon @click="handleRemoveTask(index)" class="icon" type="close" />
          </div>
        </a-form-model-item>
        <a-form-model-item :key="'name' + index" :prop="`dspTasks[${index}].taskName`" label="任务名称" :rules="[{ required: true, message: '请输入任务名称', trigger: 'change' }]">
          <a-input v-model.trim="item.taskName" placeholder="请输入任务名称"></a-input>
        </a-form-model-item>
        <a-form-model-item :key="'cpc' + index" :prop="`dspTasks[${index}].taskCpc`" label="CPC价格" :rules="[{ required: true, message: '请输入CPC价格', trigger: 'change' }]">
          <a-input type="number" class="cpc-input" v-model.number.trim="item.taskCpc" placeholder="请输入CPC价格">
            <span class="percent-text" slot="suffix">{{ moneyType }}</span>
          </a-input>
        </a-form-model-item>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      formqueryTemp: {
        secretKey: undefined,
        keyId: undefined,
        dspTaskSwitch: 0,
        dspTasks: [
          {
            taskName: undefined,
            taskCpc: undefined
          }
        ]
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('update:query', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  props: {
    query: {
      default: () => ({
        secretKey: undefined,
        keyId: undefined
      }),
      type: Object
    },
    platId: {
      default: 0,
      type: [Number, String]
    },
    labelSpan: {
      default: 5,
      type: Number
    }
  },
  created () {
    this.$set(this.formquery, 'secretKey', this.query.secretKey || undefined)
    this.$set(this.formquery, 'keyId', this.query.keyId || undefined)
    this.$set(this.formquery, 'dspTaskSwitch', this.query.dspTaskSwitch || 0)
    this.$set(this.formquery, 'dspTasks', this.query.dspTasks || [
      {
        taskName: undefined,
        taskCpc: undefined
      }
    ])
  },
  methods: {
    handleAddTask () {
      this.formquery.dspTasks.push({
        taskName: undefined,
        taskCpc: undefined
      })
      this.$message.success('新增任务成功！')
    },
    handleRemoveTask (index) {
      this.formquery.dspTasks.splice(index, 1)
      this.$message.success('移除任务成功！')
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear-task{
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .icon{
    cursor: pointer;
  }
}
.cpc-input{
  position: relative;
  &::before{
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -20px;
    background-color: @compBorderColor;
  }
}
</style>
